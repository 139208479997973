import React, { useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import QuestionCircles from "./QuestionCircles";
import { IoIosSend } from "react-icons/io";
import "./App.css";

const App = () => {
  const [page, setPage] = useState("workload");
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [selectedWorkload, setSelectedWorkload] = useState([]);
  const [showThankYou, setShowThankYou] = useState(false);
  const [remainingTime, setRemainingTime] = useState(10);
  const [isSelecting, setIsSelecting] = useState(false);

  const apiAddress = process.env.REACT_APP_API_ADDRESS;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const togglePage = () => {
    setPage((prevPage) => (prevPage === "workload" ? "assets" : "workload"));
  };

  const handleButtonClick = () => {
    const payload = {
      assets: selectedAssets,
      workload: selectedWorkload,
    };

    fetch(apiAddress + "/api", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(() => {
        setSelectedAssets([]);
        setSelectedWorkload([]);
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });
    setShowThankYou(true);
    let timeLeft = 10;
    const timer = setInterval(() => {
      timeLeft--;
      setRemainingTime(timeLeft);
      if (timeLeft <= 0) {
        clearInterval(timer);
        setShowThankYou(false);
        setPage("workload");
      }
    }, 1000);
  };

  const updateSelectedItems = (prevSelectedItems, circle, circleType) => {
    const circleExists = prevSelectedItems.some(
      (selectedCircle) => selectedCircle.id === circle.id
    );
    if (circleType === "modal" && circle.text && circleExists) {
      return prevSelectedItems.map((item) =>
        item.id === circle.id ? circle : item
      );
    }
    if (circleType === "modal" && circle.text && !circleExists) {
      return [...prevSelectedItems, circle];
    }
    if (circleType === "modal" && !circle.text && circleExists) {
      return prevSelectedItems.filter(
        (selectedCircle) => selectedCircle.id !== circle.id
      );
    }
    if (circleType === "modal") return prevSelectedItems;
    if (circleExists) {
      return prevSelectedItems.filter(
        (selectedCircle) => selectedCircle.id !== circle.id
      );
    } else {
      return [...prevSelectedItems, circle];
    }
  };

  const handleCircleClick = (circle, circleType, type) => {
    if (type === "assets") {
      setSelectedAssets((prevSelectedAssets) =>
        updateSelectedItems(prevSelectedAssets, circle, circleType)
      );
    } else if (type === "workload") {
      setSelectedWorkload((prevSelectedWorkload) =>
        updateSelectedItems(prevSelectedWorkload, circle, circleType)
      );
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: isSelecting ? undefined : togglePage,
    onSwipedRight: isSelecting ? undefined : togglePage,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div
      {...handlers}
      className="container"
      onMouseDown={() => setIsSelecting(true)}
      onMouseUp={() => setIsSelecting(false)}
    >
      <SwitchTransition>
        <CSSTransition
          key={page}
          addEndListener={(node, done) =>
            node.addEventListener("transitionend", done, false)
          }
          classNames="fade"
        >
          <>
            {showThankYou ? (
              <div className="thankYouMessage">
                <p className="centeredText">Kiitos palautteen antamisesta!</p>
                <p className="centeredText">
                  Palaat etusivulle {remainingTime} sekunnin kuluttua
                </p>
              </div>
            ) : (
              <>
                {page === "workload" && (
                  <QuestionCircles
                    selectedCircles={selectedWorkload}
                    handleCircleClick={handleCircleClick}
                    type="workload"
                  />
                )}
                {page === "assets" && (
                  <QuestionCircles
                    selectedCircles={selectedAssets}
                    handleCircleClick={handleCircleClick}
                    type="assets"
                  />
                )}
                <div
                  className={`centeredButtonContainer ${
                    page === "workload" ? "singleButton" : ""
                  }`}
                >
                  {page === "assets" && (
                    <>
                      <button
                        className="button"
                        style={{ float: "left" }}
                        onClick={() => setPage("workload")}
                      >
                        Edellinen
                      </button>
                      <button
                        className="button"
                        onClick={handleButtonClick}
                        disabled={
                          selectedAssets.length === 0 &&
                          selectedWorkload.length === 0
                        }
                      >
                        Lähetä <IoIosSend style={{ marginLeft: "8px" }} />
                      </button>
                    </>
                  )}
                  {page === "workload" && (
                    <button
                      className="button"
                      onClick={() => setPage("assets")}
                    >
                      Seuraava
                    </button>
                  )}
                </div>
              </>
            )}
          </>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default App;
